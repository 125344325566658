import React from "react"

export default function ButtonIcon(props) {
  return (
    <span className="ButtonIcon">
      <svg className="btn-icon color-invert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.977 10.235" role="graphics-document">
        <polygon points="10.933 .048 2.465 0 2.459 1.63 8.224 1.662 0 9.082 1.208 10.235 9.432 2.816 9.467 8.016 10.977 8.011 10.933 .048" fill="#fff" fillRule="evenodd"/>
      </svg>
        <svg className="btn-icon clone color-invert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.977 10.235">
          <polygon points="10.933 .048 2.465 0 2.459 1.63 8.224 1.662 0 9.082 1.208 10.235 9.432 2.816 9.467 8.016 10.977 8.011 10.933 .048" fill="#fff" fillRule="evenodd"/>
        </svg>
    </span>
  )
}
