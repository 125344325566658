import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import ScrollTransition from "../ScrollTransition"

import '../../css/components/header/Contact.scss'

export default function HeaderContact(props) {
  const response = useStaticQuery(graphql`
    query HeaderContactQuery {
      wp {
        global {
          global {
            contactPage {
              ... on WpPage {
                slug
              }
            }
            emailAddress
            phoneNumber
          }
        }
      }
    }
  `)

  if (!response || !response.wp || !response.wp.global) return <div />
  const global = response.wp.global.global

  const EmailLink = () => {
    if (!global) return;

    if (global.contactPage.slug && global.emailAddress) {
      return (
        <Link to={`/${global.contactPage.slug}`} data-cursor-non-label>
          {global.emailAddress}
        </Link>
      )
    }

    if (global.emailAddress) {
      return (
        <a href={`mailto:${global.emailAddress}`} data-cursor-non-label>{global.emailAddress}</a>
      )
    }

    return <span className="empty" />
  }

  return (
    <div className="HeaderContact">
      <ScrollTransition delay={0.25}>
        <EmailLink />
        {global.phoneNumber && (
          <a href={`tel:${global.phoneNumber.replace(/[.]/g, '')}`} data-cursor-non-label>{global.phoneNumber}</a>
        )}
      </ScrollTransition>
    </div>
  )
}
