import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

export default function NavigationSidebar(props) {
  const response = useStaticQuery(graphql`
    query NavigationSidebarQuery {
      wp {
        global {
          global {
            contactPage {
              ... on WpPage {
                slug
              }
            }
            cityStateZip
            emailAddress
            facebook
            fieldGroupName
            instagram
            linkedin
            phoneNumber
            streetAddress
            twitter
            vimeo
          }
        }
      }
    }
  `)

  if (!response || !response.wp || !response.wp.global) return <aside />
  const global = response.wp.global.global

  const EmailLink = () => {
    if (!global) return;

    if (global.contactPage.slug && global.emailAddress) {
      return (
        <Link to={`/${global.contactPage.slug}`} tabIndex={props.tabIndex}>
          {global.emailAddress}
        </Link>
      )
    }

    if (global.emailAddress) {
      return (
        <a href={`mailto:${global.emailAddress}`} tabIndex={props.tabIndex}>{global.emailAddress}</a>
      )
    }

    return null
  }

  return (
    <aside className="NavigationSidebar">
      <div className="contact-container">
        <React.Fragment>
          <span>Say Hi:</span>
          <EmailLink />
          {global.phoneNumber && (
            <a href={`tel:${global.phoneNumber.replace(/[.]/g, '')}`} tabIndex={props.tabIndex}>{global.phoneNumber}</a>
          )}
        </React.Fragment>
      </div>
      <div className="address-container">
        {/*<a href={`https://www.google.com/maps?q=`+encodeURIComponent(global.streetAddress+' '+global.cityStateZip)} target="_blank" rel="noreferrer">*/}
          {global.streetAddress && <span>{global.streetAddress}</span>}
          {global.cityStateZip && <span>{global.cityStateZip}</span>}
        {/*</a>*/}
      </div>
      <div className="social-container">
        {global.instagram && (
          <a href={`${global.instagram}`} target="_blank" rel="noreferrer" tabIndex={props.tabIndex}>
            Instagram
          </a>
        )}
        {global.linkedin && (
          <a href={`${global.linkedin}`} target="_blank" rel="noreferrer" tabIndex={props.tabIndex}>
            LinkedIn
          </a>
        )}
        {global.facebook && (
          <a href={`${global.facebook}`} target="_blank" rel="noreferrer" tabIndex={props.tabIndex}>
            Facebook
          </a>
        )}
        {global.vimeo && (
          <a href={`${global.vimeo}`} target="_blank" rel="noreferrer" tabIndex={props.tabIndex}>
            Vimeo
          </a>
        )}
        {global.twitter && (
          <a href={`${global.twitter}`} target="_blank" rel="noreferrer" tabIndex={props.tabIndex}>
            X
          </a>
        )}
      </div>
    </aside>
  )
}
