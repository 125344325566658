import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

export default function NavigationLinks(props) {
  const response = useStaticQuery(graphql`
    query NavigationLinksQuery {
      wpMenu(locations: { eq: PRIMARY_MENU }) {
        menuItems {
          nodes {
            label
            uri
            connectedNode {
              node {
                ... on WpPage {
                  id
                  title
                  slug
                }
              }
            }
          }
        }
      }
    }
  `)

  if ( !response || !response.wpMenu ) return <nav />
  const menuItems = response.wpMenu.menuItems

  return (
    <nav className="NavigationLinks">
      <div className="links-container">
        {menuItems.nodes &&
          menuItems.nodes.map((item, index) => {
            if (item.connectedNode) {
              const linkedPage = item.connectedNode.node

              return (
                <div key={`nav-links-${index}`} className="link-container" data-cursor-non-label>
                  <Link to={`/${linkedPage.slug}`} tabIndex={props.tabIndex}>{linkedPage.title}</Link>
                </div>
              )
            } else if (item.uri) {
              return  (
                <div key={`nav-links-${index}`} className="link-container" data-cursor-non-label>
                  <Link to={`${item.uri}`} tabIndex={props.tabIndex}>{item.label}</Link>
                </div>
              )
            }

            return <div className="empty" />
          })}
      </div>
    </nav>
  )
}
