import React from "react"
import markerSDK from "@marker.io/browser"
import { gsap } from "gsap"

import Header from "./src/components/Header"

import "./src/css/global/normalize.scss"
import "./src/css/global/common.scss"
import "./src/css/global/typography.scss"

export const wrapPageElement = ({ element, props }) => {
  return <Header {...props}>{element}</Header>
}

const settings = {
  debounceStrength: 20,
  transitionDuration: 0.3,
  scale: {
    initial: 0.12,
    nonLabelElement: 0.5,
    labelElement: 1,
    duration: 0.025,
  },
  blendModeTimer: null,
  textTimer: null,
  timeout: undefined,
}

// Create cursor DOM elements
function renderMouseTracking() {
  const body = document.querySelector("body")
  if (!body) return

  body.classList.remove("video-take-over")

  const cursorOne = document.createElement("div")
  cursorOne.id = "mouse-cursor-one"

  const cursorTwo = document.createElement("div")
  cursorTwo.id = "mouse-cursor-two"

  const label = document.createElement("span")
  label.classList.add("mouse-cursor-label")

  cursorTwo.appendChild(label)
  body.appendChild(cursorOne)
  body.appendChild(cursorTwo)
}

// Move mouse cursor given mouse move event
function moveMouseCursor(event, cursorOne, cursorTwo) {
  if (!event || !cursorOne || !cursorTwo) return
  const clientX = event.clientX
  const clientY = event.clientY

  const cursorWidth = cursorOne.clientWidth
  const cursorHeight = cursorOne.clientHeight

  gsap.to([cursorOne, cursorTwo], {
    top: `${clientY - cursorWidth / 2}px`,
    left: `${clientX - cursorHeight / 2}px`,
    duration: settings.transitionDuration,
    overwrite: "auto",
  })
}

// Add event listeners to DOM elements and call handlers
function updateMouseTracking() {
  const cursorOne = document.getElementById("mouse-cursor-one")
  const cursorTwo = document.getElementById("mouse-cursor-two")
  if (!cursorOne || !cursorTwo) return
  window.addEventListener("mousemove", function (event) {
    if (typeof settings.timeout === "undefined") {
      settings.timeout = setTimeout(function () {
        moveMouseCursor(event, cursorOne, cursorTwo)
        settings.timeout = undefined
      }, settings.debounceStrength)
    }
  })
}

// Update cursor when hovering over an element with a label
// data attribute, scale the cursor up and show the label. Unset
// blend modes for cursor so you can read the text label
function labelElementHover(target) {
  const cursorOne = document.getElementById("mouse-cursor-one")
  const cursorTwo = document.getElementById("mouse-cursor-two")
  const cursorLabel = cursorTwo.querySelector(".mouse-cursor-label")
  if (!cursorOne || !cursorTwo || !cursorLabel) return

  clearTimeout(settings.blendModeTimer)
  clearTimeout(settings.textTimer)

  cursorOne.style.mixBlendMode = "unset"
  cursorTwo.style.mixBlendMode = "unset"

  gsap.to([cursorOne, cursorTwo], {
    duration: settings.scale.duration,
    scale: settings.scale.labelElement,
    overwrite: "auto",
    ease: "none",
  })

  settings.textTimer = setTimeout(function () {
    const labelText = target.getAttribute("data-cursor-label")
    let labelMarkup = ""

    if (labelText) {
      let isStartOfWord = true

      for (const letter of labelText) {
        if (letter !== " ") {
          if (isStartOfWord) {
            labelMarkup += `<div class='word-wrap'>`
            isStartOfWord = false
          }
          labelMarkup += `<span>${letter}</span>`
        } else {
          labelMarkup += "</div>"
          labelMarkup += letter
          isStartOfWord = true
        }
      }
    }

    cursorLabel.innerHTML = labelMarkup
    cursorLabel.classList.add("hover-active")

    const letters = cursorLabel.querySelectorAll("span")
    if (letters) {
      for (let i = 0; i < letters.length; i++) {
        gsap.to(letters[i], {
          opacity: 1,
          delay: 0.05 * i,
        })
      }
    }
  }, settings.transitionDuration * 500)
}

// Scale the cursor up when hovering over an element that's marked with
// a data attribute, but does not include a label
function nonLabelElementHover() {
  const cursorOne = document.getElementById("mouse-cursor-one")
  const cursorTwo = document.getElementById("mouse-cursor-two")
  const cursorLabel = cursorTwo.querySelector(".mouse-cursor-label")
  if (!cursorOne || !cursorTwo || !cursorLabel) return

  clearTimeout(settings.blendModeTimer)
  clearTimeout(settings.textTimer)

  cursorOne.style.mixBlendMode = "darken"
  cursorTwo.style.mixBlendMode = "difference"

  gsap.to([cursorOne, cursorTwo], {
    duration: settings.scale.duration,
    scale: settings.scale.nonLabelElement,
    overwrite: "auto",
    ease: "none",
  })

  cursorLabel.innerHTML = ""
}

// Catch all cursor reset
function returnCursorToDefault() {
  const cursorOne = document.getElementById("mouse-cursor-one")
  const cursorTwo = document.getElementById("mouse-cursor-two")
  const cursorLabel = cursorTwo.querySelector(".mouse-cursor-label")
  if (!cursorOne || !cursorTwo || !cursorLabel) return

  gsap.to([cursorOne, cursorTwo], {
    duration: settings.scale.duration,
    scale: settings.scale.initial,
    overwrite: "auto",
    ease: "none",
  })

  clearTimeout(settings.blendModeTimer)
  clearTimeout(settings.textTimer)

  cursorOne.style.mixBlendMode = "darken"
  cursorTwo.style.mixBlendMode = "difference"

  cursorLabel.innerHTML = ""
}

function labelElementMouseLeave() {
  const cursorOne = document.getElementById("mouse-cursor-one")
  const cursorTwo = document.getElementById("mouse-cursor-two")
  const cursorLabel = cursorTwo.querySelector(".mouse-cursor-label")
  if (!cursorOne || !cursorTwo || !cursorLabel) return

  clearTimeout(settings.blendModeTimer)
  clearTimeout(settings.textTimer)

  cursorLabel.classList.remove("hover-active")

  gsap.to([cursorOne, cursorTwo], {
    duration: settings.scale.duration,
    scale: settings.scale.initial,
    overwrite: "auto",
    ease: "none",
  })

  settings.blendModeTimer = setTimeout(function () {
    cursorOne.style.mixBlendMode = "darken"
    cursorTwo.style.mixBlendMode = "difference"
    cursorLabel.innerHTML = ""
  }, settings.transitionDuration * 1000)

  cursorLabel.innerHTML = ""
}

// Bind cursor events to the DOM elements. Attach mouseenter and mouseleave
// event listeners and call handler functions as needed
function initMouseHover() {
  let hoverable = []

  const hoverNonLabels = document.querySelectorAll(
    "label, input, select, textarea, button, a, [data-cursor-non-label]",
  )
  for (let i = 0; i < hoverNonLabels.length; i++) {
    hoverable.push(hoverNonLabels[i])
  }

  const hoverLabels = document.querySelectorAll("[data-cursor-label]")
  for (let i = 0; i < hoverLabels.length; i++) {
    hoverable.push(hoverLabels[i])
  }

  for (let i = 0; i < hoverable.length; i++) {
    hoverable[i].addEventListener("mouseenter", function (event) {
      const target = event.target

      if (target.hasAttribute("data-cursor-label")) {
        labelElementHover(target)
      } else {
        nonLabelElementHover()

        if (target.dataset.hoverPreviewImageSrc) {
          let displayImageOnSide = "left";

          const imageSrc = target.dataset.hoverPreviewImageSrc
          const imageWidth = target.dataset.hoverPreviewImageWidth
          const imageHeight = target.dataset.hoverPreviewImageHeight

          const mouseCursorOne = document.querySelector("#mouse-cursor-one")
          const mouseCursorTwo = document.querySelector("#mouse-cursor-two")

          mouseCursorOne.style.mixBlendMode = "unset"
          mouseCursorTwo.style.mixBlendMode = "unset"

          if (typeof window !== undefined) {
            const hoverTargetRect = hoverable[i].getBoundingClientRect();
            const windowWidth = window.innerWidth;

            if (windowWidth && hoverTargetRect.x) {
              if (hoverTargetRect.x > windowWidth / 2) {
                displayImageOnSide = "right";
              }
            }
          }

          if (mouseCursorOne.querySelector("img")) {
            const image = mouseCursorOne.querySelector("img")
            image.style.width = `${imageWidth}px`
            image.style.height = `${imageHeight}px`

            image.classList.remove("display-image-on-left-side");
            image.classList.remove("display-image-on-right-side");

            image.classList.add(`display-image-on-${displayImageOnSide}-side`);

            image.src = imageSrc
          } else {
            const image = document.createElement("img")
            image.classList.add("cursor-preview-image")
            image.classList.remove("display-image-on-left-side");
            image.classList.remove("display-image-on-right-side");

            image.classList.add(`display-image-on-${displayImageOnSide}-side`);

            image.style.width = `${imageWidth}px`
            image.style.height = `${imageHeight}px`

            image.src = imageSrc

            mouseCursorOne.appendChild(image)
          }
        }
      }
    })

    hoverable[i].addEventListener("mouseleave", function (event) {
      const target = event.target

      if (target.hasAttribute("data-cursor-label")) {
        labelElementMouseLeave()
      } else {
        returnCursorToDefault()

        if (target.dataset.hoverPreviewImageSrc) {
          const mouseCursorOne = document.querySelector("#mouse-cursor-one")
          const mouseCursorTwo = document.querySelector("#mouse-cursor-two")

          mouseCursorOne.style.mixBlendMode = "darken"
          mouseCursorTwo.style.mixBlendMode = "difference"

          if (mouseCursorOne.querySelector("img")) {
            const image = mouseCursorOne.querySelector("img")
            image.remove()
          }
        }
      }
    })
  }
}

function videoPlaybackEnd() {
  const body = document.querySelector("body")
  const cursorOne = document.getElementById("mouse-cursor-one")
  const cursorTwo = document.getElementById("mouse-cursor-two")
  if (!body || !cursorOne || !cursorTwo) return

  gsap.to([cursorOne, cursorTwo], {
    duration: settings.transitionDuration,
    overwrite: "auto",
    opacity: 1,
    ease: "none",
  })

  body.classList.remove("show-cursor")
}

function toggleTakeOver() {
  const body = document.querySelector("body")
  if (!body) return
  body.classList.toggle("video-take-over")
}

function onDocumentMouseEvents() {
  if (typeof document === "undefined") return

  document.addEventListener("mouseleave", function () {
    const cursorOne = document.getElementById("mouse-cursor-one")
    const cursorTwo = document.getElementById("mouse-cursor-two")

    if (!cursorOne && !cursorTwo) return

    cursorOne.classList.add("document-out")
    cursorTwo.classList.add("document-out")
  })

  document.addEventListener("mouseenter", function () {
    const cursorOne = document.getElementById("mouse-cursor-one")
    const cursorTwo = document.getElementById("mouse-cursor-two")

    if (!cursorOne && !cursorTwo) return

    cursorOne.classList.remove("document-out")
    cursorTwo.classList.remove("document-out")
  })
}

export const onInitialClientRender = () => {
  renderMouseTracking()
  updateMouseTracking()
  onDocumentMouseEvents()

  // These functions are called by /template components
  window.initMouseHover = initMouseHover
  window.onRouteChange = function () {
    returnCursorToDefault()
    labelElementMouseLeave()
    const body = document.querySelector("body")
    if (!body) return
    body.classList.remove("video-take-over")
  }

  window.returnCursorToDefault = returnCursorToDefault
  window.videoPlaybackEnd = videoPlaybackEnd
  window.toggleTakeOver = toggleTakeOver

  if ( window && window.location ) {
    const hostNamesToShowMarkerOn = ["localhost", "dev--36creative.netlify.app"]

    if (hostNamesToShowMarkerOn.includes(window.location.hostname)) {
      markerSDK.loadWidget({
        destination: "663a66f2903bcd68a7180ff4",
      })
    }
  }
}
