import React from "react"
import { Link } from "gatsby"

import ScrollTransition from "../ScrollTransition"

export default function HeaderLogo(props) {
  return (
    <div className="HeaderLogo" data-cursor-non-label>
      <ScrollTransition delay={0.55}>
        <Link to="/">
          <svg
            viewBox="0 0 77.5 85.8"
            width="77.5"
            height="85.8"
            className="color-invert"
          >
            <polygon
              points="31.8 19.1 13 19.1 7.1 31.1 25.7 31.1 31.8 19.1"
              fill="white"
            />
            <path
              d="M59.8,15.8c-1.2,0-2.4,0-3.6,.3L64.2,0h-14L26.1,46.8l1.1,.2c6.4,1.3,11,6.9,11,13.4s-6.1,13.7-13.7,13.7-9.7-2.8-12-7.2H0c2.9,10.8,13,18.9,24.5,18.9s23.2-9.2,25.1-21.5c1.9,.8,3.8,1.4,5.8,1.7l5.8-11.3c-.4,0-.9,0-1.4,0-7.5,0-13.7-6.1-13.7-13.7s6.1-13.7,13.7-13.7,9.5,2.8,11.8,6.9l5.8-11.4c-4.6-4.5-10.8-7.2-17.7-7.2Z"
              fill="white"
            />
          </svg>
        </Link>
      </ScrollTransition>
    </div>
  )
}
