import React, {useState, useEffect} from "react";

import '../css/components/ScrollTransition.scss';

export default function ScrollTransition(props) {
    const [className, setClassName] = useState('');
    const element = React.createRef();

    const styles = {}

    if (typeof props.delay !== 'undefined') {
        styles.transitionDelay = `${props.delay}s`
    }

    if (typeof props.inline !== 'undefined') {
        styles.display = "inline-block"
    }

    if ( typeof props.zIndex !== 'undefined' ) {
        styles.position = "relative"
        styles.zIndex = props.zIndex
    }

    useEffect(() => {
        if (!element.current) return;

        const observer = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    setClassName('active')
                    if (props.callback) {
                        props.callback();
                    }
                }
            });
        }, {
            threshold: props.threshold || 0.1,
            rootMargin: props.rootMargin || '0px'
        })

        observer.observe(element.current);
    }, [element, props])

    return (
        <div style={styles} className={`ScrollTransition ${className} ${props.className || ''}`} ref={element}>
            {props.children}
        </div>
    )
}
