import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import ButtonIcon from "../icons/ButtonIcon"

export default function NavigationFooter(props) {
  const response = useStaticQuery(graphql`
    query NavigationFooterQuery {
      wpMenu(locations: { eq: PRIMARY_MENU }) {
        menuFooter {
          textContent
          button {
            target
            title
            url
          }
        }
        menuFooterRight {
          textContentRight
          buttonRight {
            target
            title
            url
          }
        }
      }
    }
  `)

  if (!response || !response.wpMenu) return <nav />
  const menuFooter = response.wpMenu.menuFooter
  const menuFooterRight = response.wpMenu.menuFooterRight

  return (
    <>
      <footer className="NavigationFooter left">
        <div className="menu-footer-left">
          {menuFooter.textContent && (
            <div className="text-content">
              <span>{menuFooter.textContent}</span>
            </div>
          )}
          {menuFooter.button && (
            <div className="button-container">
              <Link to={menuFooter.button.url} tabIndex={props.tabIndex} data-cursor-non-label>
                <span className="button-text">{menuFooter.button.title}</span>
                <ButtonIcon />
              </Link>
            </div>
          )}
        </div>
      </footer>
      <footer className="NavigationFooter right">
        <div className="menu-footer-right">
          {menuFooterRight.textContentRight && (
            <div className="text-content">
              <span>{menuFooterRight.textContentRight}</span>
            </div>
          )}
          {menuFooterRight.buttonRight && (
            <div className="button-container">
              <Link to={menuFooterRight.buttonRight.url} tabIndex={props.tabIndex} data-cursor-non-label>
                <span className="button-text">{menuFooterRight.buttonRight.title}</span>
                <ButtonIcon />
              </Link>
            </div>
          )}
        </div>
      </footer>
    </>

  )
}
