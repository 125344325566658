import React from "react"

import HeaderColorMode from "./header/ColorMode"
import HeaderContact from "./header/Contact"
import HeaderLogo from "./header/Logo"
import Navigation from "./Navigation"

import "../css/components/Header.scss"

export default function Header(props) {
  return (
    <>
      <header className={`Header`}>
        <div className="header-wrap">
          <Navigation />
          <HeaderContact />
          <HeaderColorMode />
          <HeaderLogo />
        </div>
      </header>
      {props.children}
    </>
  )
}
