import React, { useState, useEffect } from "react"
import { globalHistory } from "@gatsbyjs/reach-router"

import NavigationLinks from "./navigation/Links"
import NavigationFooter from "./navigation/Footer"
import NavigationSidebar from "./navigation/Sidebar"
import ScrollTransition from "./ScrollTransition"

import "../css/components/Navigation.scss"

function animateOnScroll() {

  if (typeof window !== 'undefined') {
    const showHeaderEvent = new CustomEvent('showHeader');
    const hideHeaderEvent = new CustomEvent('hideHeader');

    let timeout = undefined
    let previousPosition = undefined

    const debounceStrength = 150

    window.addEventListener("scrollbarUpdate", function(event) {
      if (typeof timeout === "undefined") {
        timeout = setTimeout(function () {
          const scrollY = -event.detail

          if (typeof previousPosition === "undefined") {
            previousPosition = event.detail
          }

          if (previousPosition > scrollY) {
            if (previousPosition - scrollY >= 20) {
              const header = document.querySelector(".Header")

              if (header && !header.classList.contains("visible-false")) {
                header.classList.remove("visible-true")
                header.classList.add("visible-false")

                window.dispatchEvent(hideHeaderEvent);
              }
            }
          } else if (scrollY === 0) {
            const header = document.querySelector(".Header")

            if (header && !header.classList.contains("visible-true")) {
              header.classList.remove("visible-false")
              header.classList.add("visible-true")

              window.dispatchEvent(showHeaderEvent);
            }
          } else if (previousPosition < scrollY) {
            if (previousPosition - scrollY <= 0) {
              const header = document.querySelector(".Header")

              if (header && !header.classList.contains("visible-true")) {
                header.classList.remove("visible-false")
                header.classList.add("visible-true")

                window.dispatchEvent(showHeaderEvent);
              }
            }
          }

          previousPosition = scrollY

          timeout = undefined
        }, debounceStrength)
      }
    })
  }
}

export default function Navigation(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const MenuToggle = function () {
    if (isMenuOpen) {
      document.querySelector("html").classList.remove("nav-open")
      setIsMenuOpen(!isMenuOpen)
    } else {
      document.querySelector("html").classList.add("nav-open")
      setIsMenuOpen(!isMenuOpen)
    }
  }
  let buttonText = "Menu",
    tabIndex = -1
  if (isMenuOpen) {
    buttonText = "Close"
    tabIndex = 0
  }

  useEffect(() => {
    animateOnScroll()

    // unlock nav when changing pages
    const unlisten = globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        if (typeof window !== 'undefined') {
          window.returnCursorToDefault()
        }

        setTimeout(() => {
          setIsMenuOpen(false)
          document.querySelector("html").classList.remove("nav-open")
        }, 300)
      }
    })

    // escape key can close nav
    const keyDownHandler = event => {
      if (event.key === "Escape") {
        event.preventDefault()
        setIsMenuOpen(false)
      }
    }

    // add key listener and then remove when gone
    document.addEventListener("keydown", keyDownHandler)
    return () => {
      document.removeEventListener("keydown", keyDownHandler)
      return unlisten
    }
  }, [])

  return (
    <div className={`Navigation is-menu-open-${isMenuOpen}`}>
      <ScrollTransition>
        <button
          className="toggle-container"
          onClick={MenuToggle}
          data-cursor-non-label
        >
          <div className="toggle-icon">
            <span className="one" />
            <span className="two" />
          </div>
          <div className="toggle-label">{buttonText}</div>
        </button>
      </ScrollTransition>
      <section className="menu-container">
        <div className="menu-scroll">
          <NavigationLinks tabIndex={tabIndex} />
          <NavigationSidebar tabIndex={tabIndex} />
          <NavigationFooter tabIndex={tabIndex} />
        </div>
        <svg
          className="curve convex color-match"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 42.333 517.166"
        >
          <path d="M0,0V517.166c24.858-42.172,42.333-142.066,42.333-258.583S24.858,42.172,0,0Z" />
        </svg>
        <svg
          className="curve concave color-match"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 42.333 517.166"
        >
          <path d="M0,517.166V258.583c0,116.517,17.475,216.411,42.333,258.583H0Z" />
          <path d="M42.333,0C17.475,42.172,0,142.066,0,258.583V0H42.333Z" />
        </svg>
      </section>
    </div>
  )
}
